import StaticMultiPageManipulator from '../StaticMultiPageManipulator';
import PagesComponentTree from '../../Sketch/component-tree/impl/PagesComponentTree';
import StaticGraphicFactory from '../../Sketch/factories/graphic/StaticGraphicFactory';
import { AnyComponentStructure } from '../../Sketch/Types';
import StaticComponentFactory from '../../Sketch/factories/component/StaticComponentFactory';
import store from '../../../redux/store/store';
import ManipulatorError from '../../Sketch/utils/manipulator-error/ManipulatorError';
import { offerAPI } from '../../../entities/offer/api/api';

class OfferPrinter extends StaticMultiPageManipulator {
	constructor(manipulatorContainer: HTMLDivElement, structure: AnyComponentStructure) {
		super(manipulatorContainer);

		this.componentTree = new PagesComponentTree(manipulatorContainer);
		this.componentFactory = new StaticComponentFactory();
		this.graphicFactory = new StaticGraphicFactory();

		this.componentTree.connectDependencies({
			graphicFactory: this.graphicFactory,
			componentFactory: this.componentFactory,
		});
		this.graphicFactory.connectDependencies({
			componentTree: this.componentTree,
		});
		this.componentFactory.connectDependencies(null);

		this.componentTree.injectDependencies();
		this.graphicFactory.injectDependencies();
		this.componentFactory.injectDependencies();

		this.componentTree.load(structure);

		this.savePreview();

		const embedElement = this.componentTree.getElementForEmbedding();
		this.manipulatorElement.append(embedElement);

		setTimeout(this.savePreview, 10000);
	}

	protected savePreviewFile = (fileID: string) => {
		const sketchID = this.getID();

		store.dispatch(offerAPI.endpoints?.updatePreview.initiate({
			preview: fileID,
			sketchId: sketchID,
		}))
			.unwrap()
			.catch(error => {
				throw new ManipulatorError(error);
			});
	};
}

export default OfferPrinter;
